/* Global Styles */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #1a1a2e; /* Match navbar color */
  color: white; /* Ensure text is readable on dark background */
}

.app {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  background-color: #1a1a2e; /* Match navbar color */
}

/* Navbar */
.navbar {
  max-width: 100%;
  background-color: #1a1a2e; /* Dark background color */
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: center; /* Center overall content */
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.navbar .navbar-content {
  max-width: 100%;
  display: flex;
  align-items: center;
  width: 900px;
}

.navbar .navbar-brand {
  margin-right: auto; /* Align brand to the left */
}

.nav-buttons {
  display: flex;
  justify-content: center; /* Center buttons within container */
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1); /* Background for buttons container */
  padding: 10px 20px;
  border-radius: 15px; /* Rounded rectangle for desktop buttons */
  gap: 20px; /* Space between buttons */
}

.button-container {
  display: flex;
  align-items: center;
}

.nav-buttons .button-container .dropdown-link {
  transition: transform 0.2s ease-in-out;
}

.nav-buttons .button-container .dropdown-link:hover {
  transform: scale(1.05); /* Slight scaling on hover */
}

.navbar .nav-buttons .nav-item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  padding: 8px 12px;
  border-radius: 8px;
  transition: background-color 0.3s ease-in-out;
}

.navbar .nav-buttons .nav-item:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Hover effect */
}

.navbar .nav-buttons .nav-item.active {
  background-color: rgba(255, 255, 255, 0.2); /* Active item */
}

.navbar .nav-buttons .nav-item span {
  font-size: 0.9rem;
}

/* Project Border */

.bordered-link {
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  display: block;
  padding: 50px;
  margin: 15px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.2);
}

.bordered-link:hover {
  text-decoration: none;
}

/* Profile Image */
.profile-image {
  width: 150px; /* Adjust size */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; /* Small rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add subtle shadow */
  object-fit: cover; /* Ensure the image scales correctly */
}

/* Section and Headers */
.header-title,
.section-title {
  color: white; /* Ensure headers are readable */
  font-size: 1.8em;
  border-bottom: 2px solid rgba(255, 255, 255, 0.3); /* Lighter border for contrast */
  padding-bottom: 5px;
  margin-bottom: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.section {
  margin-bottom: 40px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1); /* Light contrast for sections */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Links */
.link {
  color: #0073e6; /* Keep links distinguishable */
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

/* Reset link styles */
a:link,
a:visited {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  color: inherit;
}

/* Header */
.custom-header {
  background-color: #1a1a2e; /* Match navbar background */
  color: white;
  padding: 1.5rem 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
}

.header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.header-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  margin-bottom: 0.3rem;
  text-decoration: none;
}

.header-title:hover {
  text-decoration: underline;
}

.header-subtitle {
  font-size: 1.2rem;
  color: #d1d5db; /* Lighter text for subtitles */
  margin-bottom: 1rem;
}

.header-contact {
  font-size: 0.9rem;
  color: #d1d5db; /* Lighter text for smaller details */
}

.header-link {
  color: #0073e6;
  text-decoration: none;
  margin: 0 5px;
}

.header-link:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar .navbar-content > .nav-buttons {
    display: flex; /* Always display the navbar buttons */
  }
}

img[alt="Calendar"]{width: 800px; object-fit: contain;}
img[alt="Journal"]{width: 800px; object-fit: contain;}
img[alt="Task List"]{width: 800px; object-fit: contain;}
img[alt="4 Bit Adder"]{width: 800px; object-fit: contain;}
img[alt="Single Level CLA"]{width: 800px; object-fit: contain;}
img[alt="Two Level CLA"]{width: 800px; object-fit: contain;}
img[alt="Extended CLA Module"]{width: 800px; object-fit: contain;}
img[alt="2421 Corrector"]{width: 800px; object-fit: contain;}
img[alt="2421 Single"]{width: 800px; object-fit: contain;}
img[alt="2421 Two"]{width: 800px; object-fit: contain;}
img[alt="Home Page"]{width: 800px; object-fit: contain;}
img[alt="Battle"]{width: 800px; object-fit: contain;}
img[alt="Home"]{width: 800px; object-fit: contain;}
img[alt="Projects"]{width: 800px; object-fit: contain;}
img[alt="Resume"]{width: 800px; object-fit: contain;}
img[alt="Contact"]{width: 800px; object-fit: contain;}
img[alt="Landing"]{width: 800px; object-fit: contain;}
img[alt="Archive"]{width: 800px; object-fit: contain;}
img[alt="Store"]{width: 800px; object-fit: contain;}
img[alt="Shopping Cart"]{width: 800px; object-fit: contain;}
img[alt="About"]{width: 800px; object-fit: contain;}
img[alt="Predictions"]{width: 800px; object-fit: contain;}
img[alt="NVDA"]{width: 800px; object-fit: contain;}
img[alt="Flowchart"]{width: 800px; object-fit: contain;}
img[alt="Algorithm"]{width: 800px; object-fit: contain;}